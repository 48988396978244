// import React, { useState } from "react"
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../images/LOGO.svg"
import Facebook from "../images/FB.svg"
import Linkedin from "../images/IN.svg"

// const [navOpen, setNavOpen] = useState(false)

const Header = ({ siteTitle, activeNav, viewClick, navOpen, toggleNav }) => {
  const clickNav = (trueFalse, page) => {
    toggleNav();
    viewClick(trueFalse, page);
  }
  return (
    <>
      <header className={`header ${navOpen ? "active" : ""}`}>
        <div className="container">
          <div className="row jc-sb ai-center">
            <Link to="/" className="header__logo">
              <Logo />
            </Link>
            <div className="header__social">
              <a href="https://www.facebook.com/semownia" target="_blank" rel="noreferrer">
                <Facebook />
              </a>
              <div className="header__social__panel">
                <a href="https://panel.semownia.pl/" target="_blank" rel="nofollow">Panel klienta</a>
              </div>
              <div className={`hamburger ${navOpen ? "active" : "not-active"}`} onClick={() => toggleNav()}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav className={`mobileNav ${navOpen ? "active" : "not-active"}`}>
        <div className="mobileNav__wrapper">
          <ul>
            <li>
              <Link onClick={() => clickNav(true, 'google')} className={`${activeNav === 'google' ? "mobileNav__wrapper--active" : ""}`} to="#google">
                Google ADS
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'facebook')} className={`${activeNav === 'facebook' ? "mobileNav__wrapper--active" : ""}`} to="#facebook">
                FACEBOOK ADS
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'pozycjonowanie')} className={`${activeNav === 'pozycjonowanie' ? "mobileNav__wrapper--active" : ""}`} to="#pozycjonowanie">
                POZYCJONOWANIE
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'optymalizacja')} className={`${activeNav === 'optymalizacja' ? "mobileNav__wrapper--active" : ""}`} to="#optymalizacja">
                OPTYMALIZACJA
                KONWERSJI
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'analityka')} className={`${activeNav === 'analityka' ? "mobileNav__wrapper--active" : ""}`} to="#analityka">
                ANALITYKA INTERNETOWA
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'case')} className={`${activeNav === 'case' ? "mobileNav__wrapper--active" : ""}`} to="#case">
                Case Study
              </Link>
            </li>
            <li> <Link onClick={() => clickNav(true, 'promocja')} className={`${activeNav === 'promocja' ? "mobileNav__wrapper--active" : ""}`} to="#promocja">
              Promocja
            </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'omnie')} className={`${activeNav === 'omnie' ? "mobileNav__wrapper--active" : ""}`} to="#omnie">
                O mnie
              </Link>
            </li>
            <li>
              <Link onClick={() => clickNav(true, 'formularz')} className={`${activeNav === 'formularz' ? "mobileNav__wrapper--active" : ""}`} to="#formularz">
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
